import { AddressCreateDialog, Dialog, AddressForm, Layout, ViewTable } from "@/components";
import { showSuccess } from "@/components/message/message";
import Button from "@/layout/button/Button";
import WebApi from "@/web/api";
import { Form, Input, message, Space, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form/util";
import LibMD5 from "lib/md5";
import React from "react";

export default class CustomerUpdate extends React.PureComponent<RouterPropsType, {
  addressList: any[]
  customerName: string
  visible: boolean
  resetVisible: boolean
  name: string
  mobile: string
  street: string
  area: string[]
}> {

  private form = React.createRef<FormInstance>()

  private resetForm = React.createRef<FormInstance>()

  private addressForm = React.createRef<AddressForm>()

  private addressCreate = React.createRef<AddressCreateDialog>()

  private addressId: string = ''

  constructor(props) {
    super(props);
    this.state = {
      addressList: [],
      customerName: '',
      visible: false,
      resetVisible: false,
      name: '',
      mobile: '',
      area: [],
      street: '',
    };
  }

  componentDidMount() {
    this.findDetail();
  }

  findDetail = () => {
    WebApi.customer_detail({
      customerId: this.props.match?.params.id
    })
    .then((data: any) => {
      this.setState({
        addressList: data.addressList,
        customerName: data.customerName,
      });
      this.form.current?.setFieldsValue({
        customerName: data.customerName,
        mobile: data.mobile
      });
    })
  }

  render() {
    return <Layout.Page>
      <Layout flex1 scrollY>
        <Layout.Title>客户信息</Layout.Title>
        <Form style={{width: 400}} ref={this.form}>
          <Form.Item label="客户名称" name="customerName" rules={[{
            required: true,
            message: '客户名称不能为空'
          }]}>
            <Input/>
          </Form.Item>
          <Form.Item label="手机号码" name="mobile" rules={[{
            required: true,
            message: '手机号码不能为空'
          }]}>
            <Input/>
          </Form.Item>
        </Form>
        
        <Layout.Title style={{marginTop: 30}}>收货地址</Layout.Title>
        <ViewTable
          pagination={false}
          rowKey={row => row.addressId}
          dataSource={this.state.addressList}
          columns={[
            {
              title: '联系人',
              dataIndex: 'name',
              width: 150,
              render: (v, row) => {
                return <div style={{paddingLeft: 5}}>
                  {v}
                  {
                    row.isDefault === '1' &&
                    <span className="iconfont icon-moren" style={{
                      position: 'absolute',
                      lineHeight: 1,
                      top: 0,
                      left: 0,
                      color: '#ea3223',
                      fontSize: 28,
                    }}></span>
                  }
                </div>
              }
            },
            {
              title: '手机号码',
              dataIndex: 'mobile',
              width: 150,
            },
            {
              title: '地址',
              width: 'auto',
              render: (v, row) => {
                return `${row.provinceName} ${row.cityName} ${row.areaName} ${row.street}`
              }
            },
            {
              title: '操作',
              width: 140,
              align: 'center',
              render: (v, row) => {
                return <div>
                  <Tooltip title="设为默认">
                    <Button type="default" icon={<i className="iconfont icon-shezhi1"/>} onClick={() => {
                      Dialog.confirm({
                        title: '设置确认',
                        content: '确认设置为默认收货地址吗？',
                        onOk: () => {
                          WebApi.customer_address_update({
                            addressId: row.addressId,
                            isDefault: '1'
                          })
                          .then(() => {
                            showSuccess.save();
                            this.findDetail();
                          })
                        }
                      });
                    }}></Button>
                  </Tooltip>
                  <Tooltip title="修改">
                    <Button type="default" icon={<i className="iconfont icon-bianji"/>} onClick={() => {
                      this.addressId = row.addressId;
                      this.setState({
                        visible: true,
                        name: row.name,
                        mobile: row.mobile,
                        street: row.street,
                        area: [row.provinceCode, row.cityCode, row.areaCode],
                      });
                    }}></Button>
                  </Tooltip>
                  <Tooltip title="删除">
                    <Button type="default" icon={<i className="iconfont icon-delete"/>} onClick={() => {
                      Dialog.warning({
                        title: '删除确认',
                        content: '确认删除收货地址吗？',
                        onOk: () => {
                          WebApi.customer_address_delete({
                            addressId: row.addressId,
                          })
                          .then(() => {
                            showSuccess.delete();
                            this.findDetail();
                          })
                        }
                      });
                    }}></Button>
                  </Tooltip>
                </div>
              }
            }
          ]}
        />
        

        <AddressCreateDialog ref={this.addressCreate} onOk={data => {
          this.findDetail();
        }}/>

        <Dialog title="修改收货地址"
          visible={this.state.visible}
          full={false}
          onCancel={() => this.setState({visible: false})}
          onOk={this.updateAddress}
        >
          <AddressForm customerName={this.state.customerName} ref={this.addressForm} initValues={{
            name: this.state.name,
            mobile: this.state.mobile,
            street: this.state.street,
            area: this.state.area,
          }}/>
        </Dialog>

        <Dialog title="重置密码"
          full={false}
          visible={this.state.resetVisible}
          onCancel={() => this.setState({resetVisible: false})}
          onOk={this.clickResetOk}>
          <Form ref={this.resetForm} labelCol={{span: 4}} style={{width: '100%'}}>
            <Form.Item label="新密码" name="password" rules={[{
              required: true,
              message: '请填写密码'
            }, {
              min: 6,
              max: 20,
              message: '密码需要6-20位'
            }]}>
              <Input.Password placeholder="6-20位"/>
            </Form.Item>
            <Form.Item label="确认密码" name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: '请确认密码!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次密码填写不一致!'));
                },
              }),
            ]}>
              <Input.Password placeholder="6-20位"/>
            </Form.Item>
          </Form>
        </Dialog>
      </Layout>

      <Layout.FootBar>
        <Space>
          <Button type="primary" ghost onClick={() => {
            this.addressCreate.current?.open(this.state.customerName, this.props.match?.params.id);
          }}>新增收地址地址</Button>
          <Button type="primary" ghost onClick={() => {
            this.setState({
              resetVisible: true,
            });
          }}>重置密码</Button>
          <Button type="primary" onClick={this.clickSaveCustomer}>保存</Button>
        </Space>
      </Layout.FootBar>
    </Layout.Page>
  }

  clickSaveCustomer = () => {
    this.form.current?.validateFields()
    .then(data => {
      WebApi.customer_update({
        customerId: this.props.match?.params.id,
        customerName: data.customerName,
        mobile: data.mobile,
      })
      .then(() => {
        showSuccess.save();
        this.findDetail();
      });
    })
  }

  updateAddress = () => {
    this.addressForm.current?.validateFields()
    .then(data => {
      WebApi.customer_address_update({
        addressId: this.addressId,
        name: data.name,
        mobile: data.mobile,
        provinceCode: data.provinceCode,
        provinceName: data.provinceName,
        cityName: data.provinceName,
        cityCode: data.cityCode,
        areaCode: data.areaCode,
        areaName: data.areaName,
        street: data.street,
      })
      .then(() => {
        showSuccess.save();
        this.setState({
          visible: false,
        });
        this.findDetail();
      })
    })
  }

  clickResetOk = async () => {
    try {
      const values = await this.resetForm.current?.validateFields();
      if (!values) return;
      if (values.passWord !== values.confirmPassWord) {
        message.error('两次输入的密码不一致');
        return;
      }
      WebApi.customer_update({
        customerId: this.props.match?.params.id,
        password: LibMD5.hex_md5(values.password),
      })
      .then(() => {
        this.setState({
          resetVisible: false
        });
        showSuccess.save();
      })
    } catch (e) {
      console.log(e);
    }
  }
}